@each $state, $value in $theme-gradient-colors {
  .alert-#{$state} {
    @include gradient-directional(nth($value, 1) 0%, nth($value, -1) 100%, $deg: 195deg);
  }
}

.btn-close {
  &:focus {
    box-shadow: none;
  }
}

.alert-dismissible {
  .btn-close {
    background-image: none;
  }
}
