.card {
  &.card-pricing {
    .card-body {
      padding: $card-pricing-body-padding;
    }
    .table {
      tr {
        border-image: $card-pricing-border-color;
      }
      td {
        .badge {
          padding: $card-pricing-badge-padding;
          font-size: $card-pricing-badge-font-size;
          position: $card-pricing-badge-position;
          top: $card-pricing-badge-top;
        }
      }
    }
    &[data-feature="icon"] {

      .icon {
        height: $card-pricing-icon-height;
        width: $card-pricing-icon-width;
        position: $card-pricing-icon-position;
        top: $card-pricing-icon-top;
        left: 0;
        right: 0;

        i {
          color: $white;
          padding: $card-pricing-i-padding;
        }
      }
    }

    .plans {
      i {
        font-size: $card-pricing-icon-font-size;
      }
      .card-category {
        text-transform: uppercase;
      }
    }
    .icon-lg i {
      font-size: $card-pricing-icon-lg-font-size;
    }
  }
}
