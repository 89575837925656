.swal2-popup {
  border-radius: $card-border-radius;
}

.swal2-styled.swal2-confirm {
  background-image: linear-gradient(195deg, $info-gradient 0%, $info-gradient-state 100%);
  background-color: transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);

  &:hover,
  &:focus {
    background-image: linear-gradient(195deg, $info-gradient 0%, $info-gradient-state 100%);
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }
}

.swal2-confirm.btn.bg-gradient-success {
  margin-left: 10px;
  margin-right: 10px;
}

.swal2-image {
  border-radius: $border-radius-lg;
  margin-bottom: 0;
}

.swal2-close{
  outline: none;
  &:focus{
    outline: none;
    box-shadow: none;
  }
}

.swal2-input {
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
}

.swal2-icon.swal2-success {
  border-color: $success;
  color: $success;
}

.swal2-icon.swal2-info {
  border-color: $info;
  color: $info;
}

.swal2-icon.swal2-warning {
  border-color: $warning;
  color: $warning;
}

.swal2-icon.swal2-question{
  border-color: $secondary;
  color: $secondary;
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
  background-color: $success;
}
