// MultiLevel Dropdown Style

.dropdown-menu li {
  position: relative;
}

// End MultiLevel Dropdown Style

.dropdown-image {
  background-size: cover;
}

@include media-breakpoint-up(lg) {
  .dropdown-xl {
    min-width: $dropdown-xl-min-width;
  }

  .dropdown-lg {
    min-width: $dropdown-lg-min-width;
  }

  .dropdown-md {
    min-width: $dropdown-md-min-width;
  }
}

@include media-breakpoint-down(xl) {
 .dropdown-lg-responsive {
   min-width: $dropdown-lg-width-responsive;
 }
}
