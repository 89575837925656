
@mixin hover() {
  &:hover { @content; }
}

@mixin hover-focus() {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin plain-hover-focus() {
  &,
  &:hover,
  &:focus {
    @content;
  }
}

@mixin hover-focus-active() {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}
