.accordion {
  .accordion-button {
    margin: 0 auto;
    font-size: inherit !important;
  }

  .accordion-item {
    color: $body-color;
  }

  div {
    button[aria-expanded='true'] {
      .collapse-close {
        display: none;
      }

      .collapse-open {
        display: block;
      }
    }

    button[aria-expanded='false'] {
      .collapse-open {
        display: none;
      }

      .collapse-close {
        display: block;
      }
    }
  }
}
