.multisteps-form__progress {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}

.multisteps-form__progress-btn {
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  position: relative;
  padding-top: 20px;
  color: rgba($white, .4);
  text-transform: uppercase;
  font-weight: 500;
  font-size: $font-size-xs;
  text-indent: -9999px;
  border: none;
  background-color: transparent;
  outline: none !important;
  cursor: pointer;
}

@media (min-width: 500px) {
  .multisteps-form__progress-btn {
    text-indent: 0;
  }
}

.multisteps-form__progress-btn:before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 13px;
  height: 13px;
  content: '';
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  transition: all 0.15s linear 0s, -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
  transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
  transition: all 0.15s linear 0s, transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s, -webkit-transform 0.15s cubic-bezier(0.05, 1.09, 0.16, 1.4) 0s;
  border: 2px solid rgba($white, 0);
  border-radius: 50%;
  background-color: $gray-300;
  box-sizing: border-box;
  z-index: 3;
}

.bg-gradient-primary{
  .multisteps-form__progress-btn:before{
    background-color: $multistep-progress-primary-color;
  }
}

.bg-gradient-success{
  .multisteps-form__progress-btn:before{
    background-color: $multistep-progress-success-color;
  }
}

.bg-gradient-danger{
  .multisteps-form__progress-btn:before{
    background-color: $multistep-progress-danger-color;
  }
}

.bg-gradient-warning{
  .multisteps-form__progress-btn:before{
    background-color: $multistep-progress-warning-color;
  }
}

.bg-gradient-info{
  .multisteps-form__progress-btn:before{
    background-color: $multistep-progress-info-color;
  }
}

.bg-gradient-dark{
  .multisteps-form__progress-btn:before{
    background-color: $multistep-progress-dark-color;
  }
}

.bg-gradient-secondary{
  .multisteps-form__progress-btn:before{
    background-color: $multistep-progress-secondary-color;
  }
}

.bg-gradient-light{
  .multisteps-form__progress-btn:before{
    background-color: $multistep-progress-light-color;
  }
}

.multisteps-form__progress-btn:after {
  position: absolute;
  top: 5px;
  left: calc(-50% - 13px / 2);
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
  display: block;
  width: 100%;
  height: 2px;
  content: '';
  background-color: currentColor;
  z-index: 1;
}

.multisteps-form__progress-btn:first-child:after {
  display: none;
}

.multisteps-form__progress-btn.js-active {
  color: $white;
  span{
    color: rgba($white, .7);
  }
}

.multisteps-form__progress-btn.js-active:before {
  -webkit-transform: translateX(-50%) scale(1.2);
          transform: translateX(-50%) scale(1.2);
  background-color: currentColor;
}

.multisteps-form__form {
  position: relative;
}

.multisteps-form__panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  visibility: hidden;
}

.multisteps-form__panel.js-active {
  height: auto;
  opacity: 1;
  visibility: visible;
}

.multisteps-form__panel[data-animation="scaleIn"] {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.multisteps-form__panel[data-animation="scaleIn"].js-active {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  -webkit-transform: scale(1);
          transform: scale(1);
}

// CT Styling

.multisteps-form__panel {
  .multisteps-form__content {
    .btn-check:checked + .btn-outline-primary,
    .btn-check:active + .btn-outline-primary,
    .btn-outline-primary:active {
      color: $white;
    }
    .is-filled.is-focused,
    .is-focused,
    .is-filled {
      .btn {
        &:hover {
          color: $primary !important;
        }
      }
    }

    .btn i {
      font-size: 2rem;
    }
  }
}
