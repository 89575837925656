.popover {
  box-shadow: $popover-box-shadow;
}

// popover title
.popover {
  .popover-header {
    font-weight: 600;
  }
}
