.bg-primary-soft{
  background: rgba($primary-gradient, .03);
}

.bg-info-soft{
  background: rgba($info-gradient, .03);
}

.bg-success-soft{
  background: rgba($success-gradient, .03);
}

.bg-warning-soft{
  background: rgba($warning-gradient, .03);
}

.bg-danger-soft{
  background: rgba($danger-gradient, .03);
}
