@include media-breakpoint-up(lg) {
  .rtl {
    .navbar-vertical {
      .navbar-nav {
        .collapse,
        .collapsing {
          .nav-item {
            .nav-link {
              &:before {
                right: $rtl-sidebar-bullet-right !important;
                left: auto !important;
              }
            }

            .collapse,
            .collapsing {
              .nav {
                padding-right: 0;
              }
            }
          }
        }
      }
    }

    &.g-sidenav-hidden {
      .navbar-vertical {
        .navbar-nav {
          .nav-item {
            .collapse,
            .collapsing {
              .nav {
                padding-right: 0;
              }
            }
          }
        }

        &:hover {
          .navbar-nav {
            > .nav-item {
              .collapse,
              .collapsing {
                .nav {
                  padding-right: $rtl-sidebar-hover-padding-right;

                  .nav-item {
                    .collapse,
                    .collapsing {
                      .nav {
                        padding-right: 0 !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
